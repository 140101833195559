@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "VisueltPro";
  src: local("VisueltPro"),
    url("./fonts/VisueltPro-ExtraLight.woff") format("woff");
  font-weight: 200;
}
@font-face {
  font-family: "VisueltPro";
  src: local("VisueltPro"),
    url("./fonts/VisueltPro-Regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "VisueltPro";
  src: local("VisueltPro"), url("./fonts/VisueltPro-Light.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "VisueltPro";
  src: local("VisueltPro"), url("./fonts/VisueltPro-Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "VisueltPro";
  src: local("VisueltPro"), url("./fonts/VisueltPro-Bold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "VisueltPro";
  src: local("VisueltPro"), url("./fonts/VisueltPro-Black.woff") format("woff");
  font-weight: 900;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth !important;
}

*::before,
*::after {
  box-sizing: border-box;

  -webkit-box-sizing: border-box;
}

html {
  font-family: "VisueltPro", "system-ui", sans-serif;
}
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.hero-circle {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  background-color: #fff;
}

.circ1,
.circ3 {
  fill: none;
}

.circ2 {
  clip-path: url(#clip-path);
}

.circ3 {
  stroke: #fff;
  stroke-miterlimit: 10;
}

@keyframes move {
  47% {
    transform-origin: left;
    animation-mode: forwards;
    transform: scaleX(1);
  }
  50% {
    transform-origin: right;
    transform: scaleX(1);
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

@-webkit-keyframes move {
  47% {
    animation-mode: forwards;
    -webkit-transform-origin: left;
    -webkit-transform: scaleX(1);
  }
  50% {
    -webkit-transform-origin: right;
    -webkit-transform: scaleX(1);
  }
  100% {
    -webkit-transform-origin: right;
    -webkit-transform: scalex(0);
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes reveal {
  from {
    -webkit-opacity: 0;
  }
  to {
    -webkit-opacity: 1;
  }
}

.block-reveal-animation {
  position: relative;
  display: inline-block;
}
.block-reveal-animation__element {
  opacity: 0;
  -webkit-opacity: 0;
  animation-name: reveal;
  -webkit-animation-name: reveal;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;

  animation-duration: 0.01s;
  -webkit-animation-duration: 0.01s;

  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;  
}

.block-reveal-animation__block {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: all 0.5s cubic-bezier(0.86, 0, 0.07, 1);

  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  background-color: #000;

  animation-name: move;
  -webkit-animation-name: move;

  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;

  transform: scaleX(0);
  -webkit-transform: scaleX(0);

  transform-origin: left;
  -webkit-transform-origin: left;
}

.cta {
  position: relative;
  padding: 12px 18px;
  transition: all 0.3s ease;
  border: none;
  background: none;

  -webkit-transition: all 0.3s ease;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #e8e8e8;
  width: 45px;
  height: 45px;
  transition: all 0.4s ease;

  -webkit-transition: all 0.4s ease;
}

.cta span {
  position: relative;
}

.cta svg {
  position: relative;
  margin-left: 3px;
  margin-bottom: 2px;
}
.cta:hover:before {
  width: 100%;
  background: #e8e8e8;
}

.cta:hover {
  transform: translateX(12px);

  -webkit-transform: translateX(12px);
}

.cta:active {
  transform: scale(0.95);

  -webkit-transform: scale(0.95);
}

.privacy-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 1000;
}

.circ1,
.circ3 {
  fill: none;
}
.circ2 {
  clip-path: url(#clip-path);
}
.circ3 {
  stroke: #fff;
  stroke-miterlimit: 10;
}

#wwm-decor {
  transform: translate(0px, 0px);
  -webkit-transform: translate(0px, 0px);
}

@keyframes ping {
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.animate-ping {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

@-webkit-keyframes ping {
  75%,
  100% {
    -webkit-transform: scale(1.5);
    -webkit-opacity: 0;
  }
}
.animate-ping {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.rotate90 {
  transform: rotate(-89.99deg);
  -webkit-transform: rotate(-89.99deg);
}
